import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer/index"

import BackgroundImage from "../images/Beneficios/background.svg"
import ProdutosFundoVerde from "../components/Beneficios/ProdutosFundoVerde"
import GengibreCircularBeneficios from "../components/Beneficios/GengibreCircularBeneficios"
import BebidasGengibreSaborLimao from "../components/Beneficios/BebidasGengibreSaborLimao"
import CristaisEmDiscoSeisSabores from "../components/Beneficios/CristaisEmDiscoSeisSabores"
//import FotoGengibrePost from "../components/Beneficios/FotoGengibrePost"

import colors from "../styles/colors"
import { Container } from "../styles/global"

const SaibaMais = () => (
  <>
    <SEO title="Seja um parceiro!" />
    <Header />
    <OuterContainer>
      <StyledContainer>
        <SectionOne>
          <div className="text">
            <h1>Para além do sabor.</h1>
            <p>
              Os inúmeros benefícios do gengibre concentrados nas delícias
              Ardrak, tudo sem sacrificar o paladar. Você vai se surpreender!
            </p>
            <div className="links">
              <Link to="/seja-parceiro">SEJA PARCEIRO</Link>
              <Link to="/produtos" className="button">
                VER PRODUTOS
              </Link>
            </div>
          </div>
          <div className="image">
            <ProdutosFundoVerde />
          </div>
        </SectionOne>
        <SectionTwo>
          <h1>Principais benefícios para sua a saúde</h1>
          <div className="image">
            <GengibreCircularBeneficios />
          </div>
        </SectionTwo>
        <SectionThree>
          <h1>Você tem que experimentar!</h1>
          <p>
            Nada melhor que se refrescar com uma bebida saudável e deliciosa.
            Essa é a proposta da Ardrak, uma bebida exclusiva com extrato de
            gengibre sabor limão com opção sem açúcar! Veja algumas receitas de
            drinks.
          </p>
          <div className="image">
            <BebidasGengibreSaborLimao />
          </div>
        </SectionThree>
        <SectionFour>
          <h1>Você vai se apaixonar!</h1>
          <p>
            É gengibre. É saudável. É delicioso! Duvida? Só experimentar. Além
            disso, protege as cordas vocais, auxilia a digestão e protege a
            flora intestinal, combate o tártaro e gengivite e muito mais nos
            cristais de gengibre.
          </p>
          <div className="image">
            <CristaisEmDiscoSeisSabores />
          </div>
        </SectionFour>
        <SectionFive>
          <h1>Saúde é prioridade.</h1>
          <p>
            Uma paixão: gengibre. Um objetivo: saúde. Uma consequência:
            bem-estar.
          </p>
          <p>
            Todos os produtos Ardrak são naturais, com valores nutricionais
            excepcionais que trazem inúmeros benefícios.
          </p>
          <p>
            Este superalimento tem propriedades terapêuticas e é rico em
            propriedades medicinais como: cafeno, feladreno, zingibereno e
            zingerona. Contém uma substância chamada: gingerol, dotada de
            propriedades antioxidantes, anti-inflamatórias e antimicrobianas que
            protegem nosso organismo.
          </p>
        </SectionFive>
        <SectionSix>
          <div className="text">
            <h1>Emagrecimento.</h1>
            <p>
              O gengibre é rico em substâncias termogênicas que ativam o
              metabolismo potencializando a queima de gordura corporal.
            </p>
            <p>
              O gengibre é capaz de aumentar o gasto calórico durante a digestão
              e o processo metabólico. Quanto mais difícil for digerir um
              alimento mais forte será a ação termogênica da raíz. A termogênese
              é um processo regulado pelo sistema nervoso e a ação do
              superalimento favorecem o emagrecimento em até 15%.
            </p>
          </div>
        </SectionSix>
        <SectionSeven>
          <h1>Tem Ardrak pra todos os gostos</h1>
          <p>
            Confira outros produtos a base de gengibre no nosso catálogo e perto
            de você. Com certeza você vai se surpreender.
          </p>
          <div className="links">
            <Link to="/produtos">OUTROS PRODUTOS</Link>
            <a
              className="botao"
              href="https://munddi.com/ardrak/insta"
              target="_blank"
              rel="noreferrer"
            >
              ONDE ENCONTRAR
            </a>
          </div>
        </SectionSeven>
        {/* <SectionBlog>
          <h1>Veja alguns artigos</h1>
          <div className="blogposts">
            <PostPreviewLink to="/">
              <div className="top">
                <FotoGengibrePost />
              </div>
              <div className="bottom">
                Gengibre ajuda a aliviar dores musculares
              </div>
            </PostPreviewLink>
            <PostPreviewLink to="/">
              <div className="top">
                <FotoGengibrePost />
              </div>
              <div className="bottom">
                Gengibre ajuda a aliviar dores musculares
              </div>
            </PostPreviewLink>
            <PostPreviewLink to="/">
              <div className="top">
                <FotoGengibrePost />
              </div>
              <div className="bottom">
                Gengibre ajuda a aliviar dores musculares
              </div>
            </PostPreviewLink>
          </div>
        </SectionBlog> */}
      </StyledContainer>
    </OuterContainer>
    <Footer />
  </>
)

const StyledContainer = styled(Container)`
  padding-bottom: 300px;

  @media (max-width: 1220px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const OuterContainer = styled.div`
  width: 100%;
  background-image: url(${BackgroundImage});
  background-position: center top;
`

const SectionOne = styled.div`
  padding-top: 100px;
  display: flex;
  align-items: center;

  .text {
    h1 {
      max-width: 496px;
      font-size: 57px;
      font-weight: 500;
      color: ${colors.VERDE_ESCURO};
    }
    p {
      max-width: 440px;
      font-size: 26px;
      color: ${colors.CINZA_ESCURO};
    }
    .links {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;

      a:first-child {
        margin-right: 20px;
        display: inline-block;
        color: ${colors.VERDE};
        text-decoration: none;

        &:hover {
          color: ${colors.VERDE_ESCURO};
        }
      }
      .button {
        display: inline-block;
        margin-top: 10px;
        padding: 12px 30px 8px 30px;
        background-color: ${colors.VERDE_CLARO};
        border-radius: 999px;
        text-decoration: none;
        color: ${colors.VERDE};
        &:hover {
          background-color: ${colors.VERDE};
          color: white;
        }
      }
    }
  }

  .image {
    width: 100%;
    max-width: 720px;
  }
  @media (max-width: 1225px) {
    align-items: start;
    flex-direction: column-reverse;

    padding-top: 5px;

    .image {
      align-self: center;
      width: 455px;
    }
    h1 {
      padding-top: 20px;
    }
  }
  @media (max-width: 670px) {
    .text {
      h1 {
        font-size: 55px;
      }
      .links {
        font-size: 18px;

        .button {
          padding: 10px 20px;
        }
      }
    }
  }
`

const SectionTwo = styled.div`
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    max-width: 690px;

    font-size: 75px;
    font-weight: 500;
    text-align: center;
    color: ${colors.VERDE_ESCURO};
  }
  .image {
    padding-top: 60px;
    width: 100%;
    max-width: 730px;
  }
  @media (max-width: 1225px) {
    margin-top: 150px;
  }
  @media (max-width: 670px) {
    h1 {
      font-size: 40px;
    }
  }
`

const SectionThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 260px;
  h1 {
    max-width: 1260px;

    font-size: 100px;
    font-weight: 500;
    text-align: center;
    color: ${colors.ROXO_ESCURO_DOIS};
  }
  p {
    margin-top: 50px;
    max-width: 600px;
    font-size: 28px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
    color: ${colors.CINZA_ESCURO};
  }
  .image {
    padding-top: 60px;
    width: 100%;
    max-width: 430px;
  }
  @media (max-width: 670px) {
    margin-top: 110px;
    h1 {
      font-size: 40px;
    }
    .image {
      max-width: 260px;
    }
  }
`

const SectionFour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 260px;
  h1 {
    max-width: 1260px;

    font-size: 100px;
    font-weight: 500;
    text-align: center;
    color: ${colors.MARROM};
  }
  p {
    margin-top: 40px;
    max-width: 600px;
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
    text-align: center;
    color: ${colors.CINZA_ESCURO};
  }
  .image {
    padding-top: 60px;
    width: 100%;
    max-width: 530px;
  }
  @media (max-width: 670px) {
    margin-top: 110px;
    h1 {
      font-size: 40px;
    }
  }
`

const SectionFive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-top: 260px;
  h1 {
    max-width: 1260px;
    margin-bottom: 50px;

    font-size: 100px;
    font-weight: 500;
    color: ${colors.VERDE_ESCURO};
  }
  p {
    margin-bottom: 50px;
    max-width: 600px;
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
    color: ${colors.CINZA_ESCURO};
  }
  @media (max-width: 670px) {
    margin-top: 110px;
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
`

const SectionSix = styled.div`
  display: flex;
  justify-content: space-evenly;

  margin-left: 150px;
  margin-top: 260px;
  h1 {
    max-width: 1260px;

    margin-bottom: 50px;
    font-size: 100px;
    font-weight: 500;
    color: ${colors.VERDE_ESCURO};
  }
  p {
    margin-bottom: 50px;
    max-width: 600px;
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
    color: ${colors.CINZA_ESCURO};
  }

  @media (max-width: 840px) {
    margin-left: 0;
  }
  @media (max-width: 670px) {
    margin-top: 110px;

    h1 {
      font-size: 40px;
    }
    p {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
`

const SectionSeven = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 260px;
  h1 {
    max-width: 1260px;

    font-size: 56px;
    font-weight: 500;
    text-align: center;
    color: ${colors.VERDE};
  }
  p {
    margin-top: 35px;
    max-width: 657px;
    font-size: 28px;
    font-weight: 300;
    line-height: 37px;
    text-align: center;
    color: ${colors.CINZA_ESCURO};
  }

  .links {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding-top: 25px;

    a {
      display: inline-block;
      margin-bottom: 10px;

      font-size: 20px;
      font-weight: 500;
      color: ${colors.VERDE};
      text-decoration: none;
      &:hover {
        color: ${colors.VERDE_ESCURO};
      }
    }
    .botao {
      margin-left: 20px;
      padding: 12px 30px 8px 30px;
      background-color: ${colors.VERDE_CLARO};
      border-radius: 999px;
      &:hover {
        background-color: ${colors.VERDE};
        color: white;
      }
    }
  }

  @media (max-width: 670px) {
    margin-top: 110px;
    h1 {
      font-size: 40px;
    }
  }
`

// const SectionBlog = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   margin-top: 260px;
//   margin-bottom: 120px;
//   h1 {
//     max-width: 1260px;

//     margin-bottom: 58px;
//     font-size: 56px;
//     text-align: center;
//     color: ${colors.PRETO_FOSCO};
//   }

//   div.blogposts {
//     display: flex;
//     flex-flow: row wrap;
//     margin: 0 auto;
//     justify-content: center;
//   }
//   @media (max-width: 670px) {
//     margin-top: 110px;
//     margin-bottom: 70px;
//     h1 {
//       font-size: 40px;
//     }
//   }
// `

// const PostPreviewLink = styled(Link)`
//   max-width: 386px;
//   height: 536px;

//   margin: 7px;
//   background: white;
//   border: 1px solid #d1d1d1;
//   box-sizing: border-box;
//   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
//   border-radius: 12px;

//   text-decoration: none;
//   color: ${colors.PRETO_FOSCO};
//   .top {
//     height: 302px;
//     width: 386px;
//     border-top-left-radius: 12px;
//     border-top-right-radius: 12px;
//   }
//   .bottom {
//     padding: 40px;
//     font-size: 36px;
//   }
//   &:hover {
//     border: 1px solid ${colors.VERDE};
//   }
//   @media (max-width: 670px) {
//     margin: 7 0;
//     height: 100%;
//     .top {
//       height: auto;
//       width: 100%;
//       max-width: 386px;
//     }
//   }
// `

export default SaibaMais
